<template>
  <template v-if="dispDiv=='reqsubjOnly'">
    <article class="content-section section-03">
      <div class="kb-table kb-table-bordered-v2">
        <table>
          <colgroup>
            <col style="width:120px;">
            <col style="width:120px;">
            <col style="width:auto;">
            <!--          <col style="width:auto;">-->
            <col style="width:221px;">
            <col style="width:120px;">
            <col style="width:200px;">
          </colgroup>
          <thead>
          <tr>
            <th><span class="th-title">부문</span></th>
            <th><span class="th-title">분야</span></th>
            <th><span class="th-title">과정명</span></th>
            <!--          <th><span class="th-title">수료여부</span></th>-->
            <th><span class="th-title">연수기간</span></th>
            <th><span class="th-title">취득성적</span></th>
            <th><span class="th-title">비고</span></th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in reqsubjList" :key="index" :class="{'kb-line-through': isDashLine(item.drgb)}">
            <td style="padding-left: 0.1em; padding-right: 0.1em;"><span class="td-text">{{ item.gjCdp }}</span></td>
            <td style="padding-left: 0.1em; padding-right: 0.1em;"><span class="td-text">{{ item.courseDivNm }}</span></td>

            <td><span class="td-text">{{ item.subjnm }}</span></td>
            <td style="padding-left: 0.1em; padding-right: 0.1em;">
              <span v-if="item.gubun === 'P'" class="td-text">{{ item.ended }}</span>
              <span v-else class="td-text">{{ item.started }} {{item.started ? '~' : ''}} {{ item.ended }}</span>
            </td>

            <td><span class="td-text">{{ item.paccept ? item.paccept : '-' }}</span></td>
            <td><span class="td-text" v-html="item.memo"></span></td>
          </tr>

<!--          <tr v-for="(item, index) in transitionalItems" :key="index">-->
<!--            <td><span class="td-text">{{ getDivText(item.gubun) }}</span></td>-->
<!--            <td><span class="td-text">{{ item.subjnm }}</span></td>-->

<!--            <td><span class="td-text">{{ item.ended }}</span></td>-->
<!--            <td><span class="td-text">{{ item.paccept ? item.paccept : '-' }}</span></td>-->
<!--            <td><span class="td-text" v-html="item.memo"></span></td>-->
<!--          </tr>-->

          </tbody>
        </table>
      </div>
      <div class="kb-table-bottom kb-table-bottom-divider">
        <p v-if="divCds.includes('R')" class="text text-muted">{{ session.lrnerNm }}님은 직무필수과정 총 {{result.jikmuReqCountDefault}}개 중 {{ result.pilsupassCnt }}개를 수료하셨습니다.</p>
      </div>
    </article>
  </template>
  <template v-else>
    <article class="content-section section-03">
    <header class="section-header">
      <h4 class="title">{{ title }}</h4>
    </header>
    <div class="kb-table kb-table-bordered-v2">
      <table>
        <colgroup>
          <col style="width:135px;">
          <col style="width:120px;">
          <col style="width:120px;">
          <col style="width:auto;">
<!--          <col style="width:auto;">-->
          <col style="width:221px;">
          <col style="width:120px;">
          <col style="width:200px;">
        </colgroup>
        <thead>
        <tr>
          <th><span class="th-title">구분</span></th>
          <th><span class="th-title">부문</span></th>
          <th><span class="th-title">분야</span></th>
          <th><span class="th-title">과정명</span></th>
<!--          <th><span class="th-title">수료여부</span></th>-->
          <th><span class="th-title">연수기간</span></th>
          <th><span class="th-title">취득성적</span></th>
          <th><span class="th-title">비고</span></th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="items.length === 0 && transitionalItems.length === 0">
          <td colspan="5" style="text-align: center !important;"><span class="td-text">-</span></td>
        </tr>

        <tr v-for="(item, index) in items" :key="index" :class="{'kb-line-through': isDashLine(item.drgb)}">
          <td><span class="td-text">{{ getDivText(item.gubun) }}</span></td>
          <td style="padding-left: 0.1em; padding-right: 0.1em;"><span class="td-text">{{ item.gjCdp }}</span></td>
          <td style="padding-left: 0.1em; padding-right: 0.1em;"><span class="td-text">{{ item.courseDivNm }}</span></td>

          <td><span class="td-text">{{ item.subjnm }}</span></td>
          <td style="padding-left: 0.1em; padding-right: 0.1em;">
            <span v-if="item.gubun === 'P'" class="td-text">{{ item.ended }}</span>
            <span v-else class="td-text">{{ item.started }} {{item.started ? '~' : ''}} {{ item.ended }}</span>
          </td>

          <td><span class="td-text">{{ item.paccept ? item.paccept : '-' }}</span></td>
          <td><span class="td-text" v-html="item.memo"></span></td>
        </tr>

<!--        <tr v-for="(item, index) in transitionalItems" :key="index">-->
<!--          <td><span class="td-text">{{ getDivText(item.gubun) }}</span></td>-->
<!--          <td><span class="td-text">{{ item.subjnm }}</span></td>-->

<!--          <td><span class="td-text">{{ item.ended }}</span></td>-->
<!--          <td><span class="td-text">{{ item.paccept ? item.paccept : '-' }}</span></td>-->
<!--          <td><span class="td-text" v-html="item.memo"></span></td>-->
<!--        </tr>-->

        </tbody>
      </table>
    </div>
    <div class="kb-table-bottom kb-table-bottom-divider">
      <p v-if="divCds.includes('R')" class="text text-muted">{{ session.lrnerNm }}님은 직무필수과정 총 {{result.jikmuReqCountDefault}}개 중 {{ result.pilsupassCnt }}개를 수료하셨습니다.</p>
      <p v-if="divCds.includes('P') && (result.jkgupGradeBookingId || result.jkgupGradeLrnMapngRsltSumupSn)" class="text text-muted">{{ session.lrnerNm }}님은 {{ result.preclassname }}을 수료하셨습니다.</p>
    </div>
  </article>
  </template>
</template>
<script>

import {capabilityGradeTableSetup} from '@/assets/js/modules/capability/capability-setup';

export default {
  name: "CapabilityGradeTable",
  components: {},
  props: {
    divCds: Array,
    items: Array,
    result: Object,
    dispDiv: String,
  },
  setup: capabilityGradeTableSetup
}
</script>
